import React from "react"
import { Container } from "react-bootstrap"
import richTextRenderer from "../helper/richTextRenderer"
import Image from "gatsby-image"

const SpecialOffer = ({ title, subTitle, content, img, seekdaId, theme, prevElement, nextElement, link }) => {

  let bookingPage = '/strandhotel-st-peter-ording/buchen/'
  switch (theme) {
    case 'meinsbur':
      bookingPage = '/hotel-meinsbur-bendestorf/buchen/'
      break;
    case 'strandhotel':
      bookingPage = '/strandhotel-st-peter-ording/buchen/'
      break;
    case 'insellodge':
      bookingPage = '/hotel-bohlendorf-ruegen/buchen/'
      break;
    case 'strandperle':
      bookingPage = '/hotel-strandperle-travemuende/buchen/'
      break;
    case 'tirol':
      bookingPage = '/hotel-tirol/buchen/'
      break;
    case 'braunfels':
      bookingPage = '/schlosshotel-braunfels/buchen/'
      break;
    default:
      break;
  }

  const linkTarget = `${bookingPage}#!/skd-ds/skd-package-view/${seekdaId}`
  
  return (
    <>
      <section className={`component specialOffers`}>
        <Container fluid className="text-center color_invert mb-5">
          <h2 className="h2 handwritten">Unsere Lieblingsangebote</h2>
        </Container>

        <Container fluid>
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <a href={linkTarget}>
                {(img && img.fields) && <img src={img.fields.file.url} className="img-fluid" alt="preview" />}
                {(img && img.fluid) && <Image fluid={img.fluid} className="img-fluid" alt="Lieblingsplatz Hotelzimmer" />}
              </a>
            </div>
            <div className="col-12 col-md-6 contentWrapper">
              <div className="offer-header">
                <h2 className="h3">{title}</h2>
                <h3 className="h5">{subTitle}</h3>
              </div>
              <div className="offer-content">
                {richTextRenderer(content)}
                <a href={linkTarget} className="btn mt-3">Jetzt Buchen >></a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SpecialOffer
