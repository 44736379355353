import React from "react"
import { Container } from "react-bootstrap"
import Icon from "../modules/icon"
import { Link } from "gatsby"
import Image from 'gatsby-image'
import Slider from 'react-slick'
import richTextRenderer from "../helper/richTextRenderer"

const NextArrow = (props) => {
  const { onClick } = props

  return (
    <button className="swiper-button-next" onClick={onClick}>
      <Icon name="chevronRight" width="50" height="50" />
    </button>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props

  return (
    <button className="swiper-button-prev" onClick={onClick}>
      <Icon name="chevronLeft" width="50" height="50" />
    </button>
  )
}

const RoomType = ({ title, content, shortDescription, price, style, images, additionalClass, theme }) => {
  
  let bookingPage = ''
  switch (theme) {
    case 'meinsbur':
      bookingPage = '/hotel-meinsbur-bendestorf/buchen'
      break;
    case 'strandhotel':
      bookingPage = '/strandhotel-st-peter-ording/buchen'
      break;
    case 'insellodge':
      bookingPage = '/hotel-bohlendorf-ruegen/buchen'
      break;
    case 'strandperle':
      bookingPage = '/hotel-strandperle-travemuende/buchen'
      break;
    case 'tirol':
      bookingPage = '/hotel-tirol/buchen'
      break;
    case 'braunfels':
      bookingPage = '/schlosshotel-braunfels/buchen'
      break;
    default:
      break;
  }

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  }
console.log(images)
  return (
    <section className={`component roomType`} style={style}>
      <div className={`${additionalClass}`}>
        <Container fluid>
          <div className="row">
            <div className="col-12 col-md-8 contentColumn">
              <h2 className="handwritten">{title}</h2>
              <p>{shortDescription}</p>
            </div>
          </div>
        </Container>

        <div className="slider-container">
          <Slider className="image-slider" {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                {image.fields && <img src={image.fields.file.url} className="img-fluid" alt="preview" />}
                {(image.fluid) && <Image fluid={image.fluid} alt="Lieblingsplatz Hotelzimmer" />}
              </div>
            ))}
          </Slider>
        </div>

        <Container fluid>
          <div className="row">
            <div className="col-12 col-md-8 contentColumn">
            <div>{richTextRenderer(content)}</div>
              <div className="mt-5 d-flex justify-content-between">
                <div>
                  {price &&
                    <p>Preis ab {price},- EUR</p>
                  }
                </div>
                <div>
                  <Link to={bookingPage} className="btn btn-dark">Jetzt Buchen</Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  )
}

export default RoomType
