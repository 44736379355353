import React from "react"
import { Container } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const Form = ({ theme }) => {

  const { meinsbur, strandperle, insellodge, strandhotel, lieblingsplatz, braunfels, tirol } = useStaticQuery(
    graphql`
    query {
      meinsbur: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strandperle: file(relativePath: { eq: "hotels/strandperle-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insellodge: file(relativePath: { eq: "hotels/insellodge-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strandhotel: file(relativePath: { eq: "hotels/strandhotel-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lieblingsplatz: file(relativePath: { eq: "hotels/lieblingsplatz-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      braunfels: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tirol: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `
  )

  let background = []
  switch (theme) {
    case 'meinsbur':
      background = [
        meinsbur.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.84) 100%)`
      ]
      break;
    case 'strandhotel':
      background = [
        strandhotel.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(80,100,120,0.67) 0%, rgba(80,100,120,0.84) 100%)`
      ]
      break;
    case 'insellodge':
      background = [
        insellodge.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(63,107,123,0.67) 0%, rgba(63,107,123,0.84) 100%)`
      ]
      break;
    case 'strandperle':
      background = [
        strandperle.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(115,150,180,0.67) 0%, rgba(115,150,180,0.84) 100%)`
      ]
      break;
    case 'braunfels':
      background = [
        braunfels.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(104,97,93,0.67) 0%, rgba(104,97,93,0.84) 100%)`
      ]
      break;
    case 'tirol':
      background = [
        tirol.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(75,109,115,0.67) 0%, rgba(75,109,115.84) 100%)`
      ]
      break;
    default:
      background = [
        lieblingsplatz.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(75,109,115,0.67) 0%, rgba(75,109,115.84) 100%)`
      ]
      break;
  }

  return (
    <BackgroundImage
      Tag="section"
      className={`component form`}
      fluid={background.reverse()}
    > 
      <Container fluid>
        <div className="col-12 col-lg-8 mx-lg-auto">
          <form id="ajaxForm" action="https://getform.io/f/bc5b3176-2cdc-404e-9eb9-dceef4c364f6" method="POST">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" aria-describedby="Name" placeholder="Name" required />
            </div>
            <div className="form-group">
              <label htmlFor="tel">Telefon</label>
              <input type="tel" className="form-control" id="tel" aria-describedby="Telefon" placeholder="Telefon" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" aria-describedby="Email" placeholder="Email" required />
            </div>
            <div className="form-group">
              <label htmlFor="msg">Wie können wir Ihnen helfen?</label>
              <textarea rows="6" className="form-control" id="msg" placeholder="Nachricht" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Absenden</button>
          </form>
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default Form
