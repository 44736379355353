import React from "react"
import Image from 'gatsby-image'

const ImageWall = ({ images, additionalClass, style }) => (
  <section className={`component imageWall bgLightDark ${additionalClass}`} style={style}>
    <div className="wrapper">
      {images.map((image, index) => (
        <figure key={index} className="brick">
          {image.fields && <img src={image.fields.file.url} alt="preview" />}
          {(image.fluid) && <Image fluid={image.fluid} alt="Lieblingsplatz Hotels" />}
        </figure>
      ))}
    </div>
  </section>
)

export default ImageWall
