import React from "react"
import { Container } from "react-bootstrap"

const TrustYou = ({ theme }) => {
  let hotelId = ''
  switch (theme) {
    case 'meinsbur':
      hotelId = '05862c6a-1ab5-4f42-b0cb-3014f9d94e68'
      break;
    case 'strandhotel':
      hotelId = '634fb696-5a9b-4397-bea8-e02c27ab757d'
      break;
    case 'insellodge':
      hotelId = 'e3237c91-da8c-4214-bb5f-6a89156350af'
      break;
    case 'strandperle':
      hotelId = '84d370da-34d8-4503-83a4-e431fcae9458'
      break;
    case 'tirol':
      hotelId = '81858087-d6be-4abd-99de-7595cb6f6c7e'
      break;
    case 'braunfels':
      hotelId = 'dd12e78b-0601-426b-9409-bbc43064e307'
      break;
    default:
      break;
  }

  return (
    <section className="component trustYou">
      <Container fluid className="color_invert">
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="handwritten text-md-right">Was unsere Kunden meinen</h3>
          </div>
          <div className="col-12 col-md-6">
            <iframe title="TrustYou" src={`https://api.trustyou.com/hotels/${hotelId}/seal.html?key=50fdd20f-8270-40ee-99a2-2c14afa3ca49&lang=de&size=m&scale=5`} allowtransparency="true" frameBorder="0" scrolling="no" height="56px" width="243px"></iframe>
          </div>
        </div>
      </Container>
    </section>
)
}

export default TrustYou
