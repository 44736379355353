import React from 'react'
import PromotionTeaser from "./promotionTeaser"
import GeneralContentFullWidth from "./generalContentFullWidth"
import ImageWall from "./imageWall"
import ContentTeaser from "./contentTeaser"
import ThreeTeaser from "./threeTeaser"
import FourTeaser from "./fourTeaser"
import RoomType from "./roomType"
import GeneralContentTwoColumn from "./generalContentTwoColumn"
import RestaurantBooking from "./restaurantBooking"
import GeneralContent from "./generalContent"
import PromotionTeaser2 from "./promotionTeaser2"
import PromotionTeaser3 from "./promotionTeaser3"
import ImageSlider from "./imageSlider"
import GoogleMaps from './googleMaps'
import Form from './form'
import Event from './event'
import SpecialOffer from './specialOffer'
import TwoTeaser from './twoTeaser'
import TrustedYou from './trustYou'

// 
// Live Content Handling
// 

export const renderDynamicComponents = (components, theme) => {
    console.log("page components", components)

    return components.map((component, index) => {
        switch (component.internal.type) {
            case 'ContentfulComponentThreeTeaser':
                return (
                    <ThreeTeaser
                        key={index}
                        additionalClass={(component.darkBackground) ? 'bgDark' : 'color_invert'}
                        icon1={component.threeTeaserIcon1}
                        title1={component.contenTeaserTitle1}
                        content1={component.threeTeaserContent1}
                        icon2={component.threeTeaserIcon2}
                        title2={component.threeTeaserTitle2}
                        content2={component.threeTeaserContent2}
                        icon3={component.threeTeaserIcon3}
                        title3={component.threeTeaserTitle3}
                        content3={component.threeTeaserContent3}
                        link1={component.threeTeaserLink1}
                        link2={component.threeTeaserLink2}
                        link3={component.threeTeaserLink3}
                    />
                )
            case 'ContentfulComponentContentTeaser':
                return (
                    <ContentTeaser
                        key={index}
                        additionalClass="bgLightDark"
                        title={component.contenTeaserTitle}
                        content={component.contenTeaserContent ? component.contenTeaserContent.json : null}
                        carousel={component.contenTeaserCarousel}
                        id={component.contentIdOptional}
                    />
                )
            case 'ContentfulComponentPromotionTeaser':
                return (
                    <PromotionTeaser
                        key={index}
                        additionalClass={component.promotionTeaserBgImage ? 'overlay' : 'color_invert'}
                        promotionTeaserBgImage={component.promotionTeaserBgImage ? component.promotionTeaserBgImage : ''}
                        promotionTeaserImageRightTop={component.promotionTeaserImageRightTop}
                        promotionTeaserImageRightBottom={component.promotionTeaserImageRightBottom}
                        promotionTeaserImageRight1={component.promotionTeaserImageRight1}
                        promotionTeaserTitleImage={component.promotionTeaserTitleImage}
                        promotionTeaserImageLeft={component.promotionTeaserImageLeft}
                        promotionTeaserContent={component.promotionTeaserContent ? component.promotionTeaserContent.json : null}
                        promotionTeaserTitle={component.promotionTeaserTitle}
                        id={component.contentIdOptional}
                    />
                )
            case 'ContentfulComponentImageWall':
                return (
                    <ImageWall
                        key={index}
                        images={component.imageWallImages}
                    />
                )
            case 'ContentfulComponentGeneralContentFullWidth':
                return (
                    <GeneralContentFullWidth
                        key={index}
                        additionalClass={component.darkBackground ? 'bgDark' : 'color_invert'}
                        title={component.contenTeaserTitle}
                        content={component.GeneralContentFullWidthContent ? component.GeneralContentFullWidthContent.json : null}
                        twoColumns={component.twoColumns}
                        handwritten={component.handwritten}
                        textCentered={component.textCentered}
                        id={component.contentIdOptional}
                    />
                )
            case 'ContentfulComponentRoomType':
                return (
                    <RoomType
                        key={index}
                        additionalClass={index % 2 === 0 ? 'color_invert' : 'overlay left'}
                        title={component.roomTypeTitle}
                        shortDescription={component.roomTypeShortDescription}
                        images={component.roomTypeImages}
                        content={component.roomTypeContent ? component.roomTypeContent.json : null}
                        price={component.roomTypePrice}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentGeneralContent':
                return (
                    <GeneralContent
                        key={index}
                        addionalClass=""
                        title={component.generalContentTitle}
                        content={component.generalContent ? component.generalContent.json : null}
                        image={component.generalContentImage}
                        textCentered={component.textCentered}
                    />
                )
            case 'ContentfulComponentPromotionTeaser2':
                if (theme === 'lieblingsplatz') {
                    return (
                        <PromotionTeaser3
                            key={index}
                            promotionTeaserImageRightTop={component.promotionTeaserImageRightTop}
                            promotionTeaserImageRightBottom={component.promotionTeaserImageRightBottom}
                            promotionTeaserImageRight1={component.promotionTeaserImageRight1}
                            promotionTeaserImageLeft={component.promotionTeaserImageLeft}
                            promotionTeaserTitleImage={component.promotionTeaserTitleImage}
                            promotionTeaserImageBottomLeft={component.promotionTeaserImageBottomLeft}
                            promotionTeaserImageBottomRight={component.promotionTeaserImageBottomRight}
                            promotionTeaserContent={component.promotionTeaserContent ? component.promotionTeaserContent.json : null}
                            promotionTeaserTitle={component.promotionTeaserTitle}
                        />
                    )
                } else {
                    return (
                        <PromotionTeaser2
                            key={index}
                            promotionTeaserImageRightTop={component.promotionTeaserImageRightTop}
                            promotionTeaserImageRightBottom={component.promotionTeaserImageRightBottom}
                            promotionTeaserImageRight1={component.promotionTeaserImageRight1}
                            promotionTeaserImageLeft={component.promotionTeaserImageLeft}
                            promotionTeaserTitleImage={component.promotionTeaserTitleImage}
                            promotionTeaserImageBottomLeft={component.promotionTeaserImageBottomLeft}
                            promotionTeaserImageBottomRight={component.promotionTeaserImageBottomRight}
                            promotionTeaserContent={component.promotionTeaserContent ? component.promotionTeaserContent.json : null}
                            promotionTeaserTitle={component.promotionTeaserTitle}
                        />
                    )
                }
            case 'ContentfulComponentGeneralContentTwoColumn':
                return (
                    <GeneralContentTwoColumn
                        key={index}
                        textCentered={component.textCentered}
                        handwritten={component.handwritten}
                        title={component.generalContentTitle}
                        title_left={component.titleLeft}
                        content_left={component.contentLeft ? component.contentLeft.json : null}
                        title_right={component.titleRight}
                        content_right={component.contentRight ? component.contentRight.json : null}
                        id={component.contentIdOptional}
                    />
                )
            case 'ContentfulComponentRestaurantBooking':
                return (
                    <RestaurantBooking 
                        key={index}
                        theme={theme} 
                    />
                )
            case 'ContentfulComponentImageSlider':
                return (
                    <ImageSlider
                        key={index}
                        additionalClass={component.darkBackground ? 'bgDark pb-5' : 'pb-5'}
                        images={component.ImageSliderImages}
                    />
                )
            case 'ContentfulComponentContactForm':
                return (
                    <Form
                        key={index}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentGoogleMaps':
                return (
                    <GoogleMaps
                        key={index}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentTrustedYou':
                return (
                    <TrustedYou
                        key={index}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentEvent':
                return (
                    <Event
                        key={index}
                        additionalClass="color_invert"
                        date={component.EventDateTime}
                        time={component.EventDateTime}
                        title={component.eventTitle}
                        image={component.eventImage}
                        content={component.eventContent.json}
                    />
                )
            case 'ContentfulComponentSpecialOffer':
                return (
                    <SpecialOffer
                        key={index}
                        title={component.OfferTitle}
                        subTitle={component.offerSubTitle}
                        content={component.OfferContent ? component.OfferContent.json : null}
                        img={component.OfferImage}
                        seekdaId={component.seekdaOfferId}
                        theme={theme}
                        link={component.link}
                    />
                )
            case 'ContentfulComponentTwoTeaser':
                return (
                    <TwoTeaser
                        key={index}
                        title={component.OfferTitle}
                        content={component.OfferContent ? component.OfferContent.json : null}
                        img={component.OfferImage}
                        linkTarget={component.linkTarget}
                        linkText={component.linkText}
                        title2={component.offerTitle2}
                        content2={component.offerContent2 ? component.offerContent2.json : null}
                        img2={component.offerImage2}
                        linkTarget2={component.linkTarget2}
                        linkText2={component.linkText2}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentFourTeaser':
                return (
                    <FourTeaser
                        key={index}
                        additionalClass={'bgDark'}
                        icon1={component.icon1}
                        title1={component.title1}
                        content1={component.text1}
                        icon2={component.icon2}
                        title2={component.title2}
                        content2={component.text2}
                        icon3={component.icon3}
                        title3={component.title3}
                        content3={component.text3}
                        icon4={component.icon4}
                        title4={component.title4}
                        content4={component.text4}
                        title={component.title}
                        theme={theme}
                    />
                )
            default:
                return null
        }
    })
}


// 
// Preview Content Handling
// 

export const renderPreviewComponents = (components, theme) => {
    console.log("page components", components)
    return components.map((component, index) => {
        switch (component.sys.contentType.sys.id) {
            case 'componentThreeTeaser':
                return (
                    <ThreeTeaser
                        key={index}
                        additionalClass={(component.fields.darkBackground) ? 'bgDark' : 'color_invert'}
                        icon1={component.fields.threeTeaserIcon1}
                        title1={component.fields.contenTeaserTitle1}
                        content1={component.fields.threeTeaserContent1}
                        icon2={component.fields.threeTeaserIcon2}
                        title2={component.fields.threeTeaserTitle2}
                        content2={component.fields.threeTeaserContent2}
                        icon3={component.fields.threeTeaserIcon3}
                        title3={component.fields.threeTeaserTitle3}
                        content3={component.fields.threeTeaserContent3}
                        link1={component.fields.hreeTeaserLink1}
                        link2={component.fields.threeTeaserLink2}
                        link3={component.fields.threeTeaserLink3}
                    />
                )
            case 'contentTeaser':
                return (
                    <ContentTeaser
                        key={index}
                        additionalClass="bgLightDark"
                        title={component.fields.contenTeaserTitle}
                        content={component.fields.contenTeaserContent ? component.fields.contenTeaserContent : null}
                        carousel={component.fields.contenTeaserCarousel}
                        id={component.fieldscontentIdOptional}
                    />
                )
            case 'promotionTeaser':
                return (
                    <PromotionTeaser
                        key={index}
                        additionalClass={component.fields.promotionTeaserBgImage ? 'overlay' : 'color_invert'}
                        promotionTeaserBgImage={component.fields.promotionTeaserBgImage ? component.fields.promotionTeaserBgImage : ''}
                        promotionTeaserImageRightTop={component.fields.promotionTeaserImageRightTop}
                        promotionTeaserImageRightBottom={component.fields.promotionTeaserImageRightBottom}
                        promotionTeaserImageRight1={component.fields.promotionTeaserImageRight1}
                        promotionTeaserTitleImage={component.fields.promotionTeaserTitleImage}
                        promotionTeaserImageLeft={component.fields.promotionTeaserImageLeft}
                        promotionTeaserContent={component.fields.promotionTeaserContent ? component.fields.promotionTeaserContent : null}
                        promotionTeaserTitle={component.fields.promotionTeaserTitle}
                        id={component.fields.contentIdOptional}
                    />
                )
            case 'imageWall':
                return (
                    <ImageWall
                        key={index}
                        images={component.fields.imageWallImages}
                    />
                )
            case 'componentGeneralContentFullWidth':
                return (
                    <GeneralContentFullWidth
                        key={index}
                        additionalClass={component.fields.darkBackground ? 'bgDark' : 'color_invert'}
                        title={component.fields.contenTeaserTitle}
                        content={component.fields.GeneralContentFullWidthContent ? component.fields.GeneralContentFullWidthContent : null}
                        twoColumns={component.fields.twoColumns}
                        handwritten={component.fields.handwritten}
                        textCentered={component.fields.textCentered}
                        id={component.fields.contentIdOptional}
                    />
                )
            case 'roomType':
                return (
                    <RoomType
                        key={index}
                        additionalClass={index % 2 === 0 ? 'color_invert' : 'overlay left'}
                        title={component.fields.roomTypeTitle}
                        shortDescription={component.fields.roomTypeShortDescription}
                        images={component.fields.roomTypeImages}
                        content={component.fields.roomTypeContent ? component.fields.roomTypeContent : null}
                        price={component.fields.roomTypePrice}
                        theme={theme}
                    />
                )
            case 'generalContent':
                return (
                    <GeneralContent
                        key={index}
                        addionalClass="pb-0"
                        title={component.fields.generalContentTitle}
                        content={component.fields.generalContent ? component.fields.generalContent : null}
                        image={component.fields.generalContentImage ? component.fields.generalContentImage.fields : null}
                        textCentered={component.fields.textCentered}
                    />
                )
            case 'promotionTeaser2':
                if (theme === 'lieblingsplatz') {
                    return (
                        <PromotionTeaser3
                            key={index}
                            promotionTeaserImageRightTop={component.fields.promotionTeaserImageRightTop}
                            promotionTeaserImageRightBottom={component.fields.promotionTeaserImageRightBottom}
                            promotionTeaserImageRight1={component.fields.promotionTeaserImageRight1}
                            promotionTeaserImageLeft={component.fields.promotionTeaserImageLeft}
                            promotionTeaserTitleImage={component.fields.promotionTeaserTitleImage}
                            promotionTeaserImageBottomLeft={component.fields.promotionTeaserImageBottomLeft}
                            promotionTeaserImageBottomRight={component.fields.promotionTeaserImageBottomRight}
                            promotionTeaserContent={component.fields.promotionTeaserContent ? component.fields.promotionTeaserContent : null}
                            promotionTeaserTitle={component.fields.promotionTeaserTitle}
                        />
                    )
                } else {
                    return (
                        <PromotionTeaser2
                            key={index}
                            promotionTeaserImageRightTop={component.fields.promotionTeaserImageRightTop}
                            promotionTeaserImageRightBottom={component.fields.promotionTeaserImageRightBottom}
                            promotionTeaserImageRight1={component.fields.promotionTeaserImageRight1}
                            promotionTeaserImageLeft={component.fields.promotionTeaserImageLeft}
                            promotionTeaserTitleImage={component.fields.promotionTeaserTitleImage}
                            promotionTeaserImageBottomLeft={component.fields.promotionTeaserImageBottomLeft}
                            promotionTeaserImageBottomRight={component.fields.promotionTeaserImageBottomRight}
                            promotionTeaserContent={component.fields.promotionTeaserContent ? component.fields.promotionTeaserContent : null}
                            promotionTeaserTitle={component.fields.promotionTeaserTitle}
                        />
                    )
                }
            case 'componentGeneralContentTwoColumn':
                return (
                    <GeneralContentTwoColumn
                        key={index}
                        textCentered={component.fields.textCentered}
                        handwritten={component.fields.handwritten}
                        title={component.fields.generalContentTitle}
                        title_left={component.fields.titleLeft}
                        content_left={component.fields.contentLeft ? component.fields.contentLeft : null}
                        title_right={component.fields.titleRight}
                        content_right={component.fields.contentRight ? component.fields.contentRight : null}
                        id={component.fields.contentIdOptional}
                    />
                )
            case 'restaurantBooking':
                return (
                    <RestaurantBooking 
                        key={index}
                        theme={theme} 
                    />
                )
            case 'imageSlider':
                return (
                    <ImageSlider
                        key={index}
                        additionalClass={component.fields.darkBackground ? 'bgDark pb-5' : 'pb-5'}
                        images={component.fields.ImageSliderImages}
                    />
                )
            case 'componentContactForm':
                return (
                    <Form
                        key={index}
                        theme={theme}
                    />
                )
            case 'componentGoogleMaps':
                return (
                    <GoogleMaps
                        key={index}
                        theme={theme}
                    />
                )
            case 'componentTrustedYou':
                return (
                    <TrustedYou
                        key={index}
                        theme={theme}
                    />
                )
            case 'event':
                return (
                    <Event
                        key={index}
                        additionalClass="color_invert"
                        date={component.fields.EventDateTime}
                        time={component.fields.EventDateTime}
                        title={component.fields.eventTitle}
                        image={component.fields.eventImage}
                        content={component.fields.eventContent ? component.fields.eventContent : null}
                    />
                )
            case 'componentSpecialOffer':
                return (
                    <SpecialOffer
                        key={index}
                        title={component.fields.OfferTitle}
                        subTitle={component.fields.offerSubTitle}
                        content={component.fields.OfferContent ? component.fields.OfferContent : null}
                        img={component.fields.OfferImage}
                        seekdaId={component.fields.seekdaOfferId}
                        theme={theme}
                        link={component.fields.link}
                    />
                )
            case 'ContentfulComponentTwoTeaser':
                return (
                    <TwoTeaser
                        key={index}
                        title={component.fields.OfferTitle}
                        content={component.fields.OfferContent ? component.fields.OfferContent.json : null}
                        img={component.fields.OfferImage}
                        linkTarget={component.fields.linkTarget}
                        linkText={component.fields.linkText}
                        title2={component.fields.offerTitle2}
                        content2={component.fields.offerContent2 ? component.fields.offerContent2.json : null}
                        img2={component.fields.offerImage2}
                        linkTarget2={component.fields.linkTarget2}
                        linkText2={component.fields.linkText2}
                        theme={theme}
                    />
                )
            case 'ContentfulComponentFourTeaser':
                return (
                    <FourTeaser
                        key={index}
                        additionalClass={'bgDark'}
                        icon1={component.fields.icon1}
                        title1={component.fields.title1}
                        content1={component.fields.text1}
                        icon2={component.fields.icon2}
                        title2={component.fields.title2}
                        content2={component.fields.text2}
                        icon3={component.fields.icon3}
                        title3={component.fields.title3}
                        content3={component.fields.text3}
                        icon4={component.fields.icon4}
                        title4={component.fields.title4}
                        content4={component.fields.text4}
                        title={component.fields.title}
                        theme={theme}
                    />
                )
            default:
                return null
        }
    })
}