import React from "react"
import { Container } from "react-bootstrap"
import richTextRenderer from "../helper/richTextRenderer"

const GeneralContentTwoColumn = ({ title, handwritten, title_left, content_left, title_right, content_right, id }) => (
  <section className="component generalContent" id={id}>
    <Container fluid className="color_invert">
      <div className="row">
        <div className="col-12">
          <h2 className={`h3 mb-3 text-center text-uppercase ${handwritten ? 'handwritten' : ''}`}>{title}</h2>
          <div className="contentColumn">
            <div className="row">
              <div className="col-md-6">
                <h3 className="h5">{title_left}</h3>
                {richTextRenderer(content_left)}
              </div>
              <div className="col-md-6">
                <h3 className="h5">{title_right}</h3>
                {richTextRenderer(content_right)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </section>
)

export default GeneralContentTwoColumn
