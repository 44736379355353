import React from "react"
import { Container } from "react-bootstrap"

const GoogleMaps = ({ theme }) => {

  let url = ''
  switch (theme) {
    case 'meinsbur':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.1053691664774!2d9.965023116115677!3d53.341369979978104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b19612a444a6eb%3A0xf98f98479250d5fb!2sMeinsbur%20Boutique%20Hotel!5e0!3m2!1sde!2sde!4v1573993314230!5m2!1sde!2sde'
      break;
    case 'strandhotel':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2327.640916152069!2d8.611811416131147!3d54.31029998019229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ad8ae61d314c37%3A0x5426dd700bf50969!2sHotel%20Lieblingsplatz%20Strandhotel!5e0!3m2!1sde!2sde!4v1597381614496!5m2!1sde!2sde'
      break;
    case 'insellodge':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2311.3370014841134!2d13.29364471614037!3d54.59805558026032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ab572f01f9ae9d%3A0x4a458ac7c3280d5f!2sHotel%20Lieblingsplatz%20Bohlendorf!5e0!3m2!1sde!2sde!4v1597381413405!5m2!1sde!2sde'
      break;
    case 'strandperle':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2346.972279306313!2d10.878281316120228!3d53.96775998011384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ad8ae61d4dfdef%3A0xe57b925e98f46aaf!2sStrandperle%2C%20Lieblingsplatz%20Hotel!5e0!3m2!1sde!2sde!4v1597381481762!5m2!1sde!2sde'
      break;
    case 'braunfels':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2537.0559063505993!2d8.390759816012908!3d50.514526579484226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bc4f17cd4ea775%3A0xd2cd4c514f4e8b90!2sSchlosshotel%20Braunfels!5e0!3m2!1sde!2sde!4v1597381648747!5m2!1sde!2sde'
      break;
    case 'tirol':
      url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2709.122433365443!2d11.87906071591573!3d47.23375097916175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4777db5cc6d619cd%3A0x9e6f4e0fbeb27498!2sDorfpl.%208a%2C%206280%20Zell%20am%20Ziller%2C%20%C3%96sterreich!5e0!3m2!1sde!2sde!4v1597381557391!5m2!1sde!2sde'
      break;
    default:
      break;
  }

  return (
    <section className="component embedHTML">
      <Container fluid>
        <div className="col-12 text-center">
          <iframe src={url} frameBorder="0" style={{border:0}} allowFullScreen="" title="embed"></iframe>
        </div>
      </Container>
    </section>
  )
}

export default GoogleMaps
