import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Icon from "../modules/icon"

const ThreeTeaser = ({ additionalClass, style, icon1, icon2, icon3, title1, title2, title3, content1, content2, content3, link1, link2, link3 }) => (
  <section className={`component threeTeaser ${additionalClass}`} style={style}>
    <Container fluid className={`${additionalClass}`}>
      <div className="row">
        <div className="col-12 col-md-10 m-auto">
          <div className="row">
            <div className="col-md-4 text-center px-md-5">
              <Icon name={icon1} width="30" height="50" />
              <h2 className="h5 mb-4 text-uppercase">{title1}</h2>
              <p className="text-left">{content1}</p>
              {link1 &&
                <Link className="text-left d-block text-underline" to={link1 ? link1.linkTarget ? link1.linkTarget : link1.document.file.url : null} target={link1.document ? '_blank' : '_self'}>{link1.title}</Link>
              }
            </div>
            <div className="col-md-4 text-center px-md-5">
              <Icon name={icon2} width="30" height="50" />
              <h2 className="h5 mb-4 text-uppercase">{title2}</h2>
              <p className="text-left">{content2}</p>
              {link2 &&
                <Link className="text-left d-block text-underline" to={link2 ? link2.linkTarget ? link2.linkTarget : link2.document.file.url : null} target={link2.document ? '_blank' : '_self'}>{link2.title}</Link>
              }
            </div>
            <div className="col-md-4 text-center px-md-5">
              <Icon name={icon3} width="30" height="50" />
              <h2 className="h5 mb-4 text-uppercase">{title3}</h2>
              <p className="text-left">{content3}</p>
              {link3 &&
                <Link className="text-left d-block text-underline" to={link3 ? link3.linkTarget ? link3.linkTarget : link3.document.file.url : null} target={link3.document ? '_blank' : '_self'}>{link3.title}</Link>
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  </section>
)

export default ThreeTeaser
