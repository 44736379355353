import React from "react"
import { Container, Row } from "react-bootstrap"
import richTextRenderer from "../helper/richTextRenderer"
import Image from "gatsby-image"

const TwoTeaser = ({ title, content, img, title2, content2, img2, theme, linkTarget, linkTarget2, linkText, linkText2 }) => {

  return (
    <>
      <section className={`component twoTeaser mb-5`}>
        <Container fluid>
          <Row className="no-gutters">
            <div className="col-md-6 leftItem">
              <div className="row no-gutters">
                <div className="col-12 col-md-6">
                  <a href={linkTarget}>
                    {(img && img.fields) && <img src={img.fields.file.url} className="img-fluid" alt="preview" />}
                    {(img && img.fluid) && <Image fluid={img.fluid} className="img-fluid" alt="Lieblingsplatz Hotels" />}
                  </a>
                </div>
                <div className="col-12 col-md-6 contentWrapper">
                  <div className="offer-header">
                    <h2 className="h3">{title}</h2>
                  </div>
                  <div className="offer-content">
                    {richTextRenderer(content)}
                    <a href={linkTarget} className="btn mt-3">{linkText}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 rightItem">
              <div className="row no-gutters">
                <div className="col-12 col-md-6">
                  <a href={linkTarget2}>
                    {(img2 && img2.fields) && <img src={img2.fields.file.url} className="img-fluid" alt="preview" />}
                    {(img2 && img2.fluid) && <Image fluid={img2.fluid} className="img-fluid" alt="Lieblingsplatz Hotels" />}
                  </a>
                </div>
                <div className="col-12 col-md-6 contentWrapper">
                  <div className="offer-header">
                    <h2 className="h3">{title2}</h2>
                  </div>
                  <div className="offer-content">
                    {richTextRenderer(content2)}
                    <a href={linkTarget2} className="btn mt-3">{linkText2}</a>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default TwoTeaser
