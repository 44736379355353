import React from "react"
import { Container } from "react-bootstrap"
import Image from "gatsby-image"
import moment from 'moment'
import 'moment/locale/de';
import richTextRenderer from "../helper/richTextRenderer"

const Event = ({ title, content, date, time, style, image, additionalClass }) => {
  moment.locale('de')
  return(
    <section className={`component event ${additionalClass}`} style={style}>
      <Container fluid className="color_invert">
        <div className="row">
          <div className="col-12 col-md-8">
            {image.fields && <img src={image.fields.file.url} alt="preview" />}
            {(image.fluid) && <Image fluid={image.fluid} alt="Lieblingsplatz Hotels" />}
          </div>
          <div className="col-12 col-md-4">
            {date &&
              <h2 className="h4">{moment(date).format('DD MMM')} · {moment(time).format('HH:mm')} Uhr</h2>
            }
            <h2 className="h3">{title}</h2>
            <div>{richTextRenderer(content)}</div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Event
