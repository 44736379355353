import React from "react"
import { Container } from "react-bootstrap"
import Image from 'gatsby-image'
import richTextRenderer from "../helper/richTextRenderer"

const PromotionTeaser3 = ({ promotionTeaserTitle, promotionTeaserContent, promotionTeaserTitleImage, promotionTeaserImageLeft, promotionTeaserImageRightTop, promotionTeaserImageRightBottom, promotionTeaserImageBottomLeft, promotionTeaserImageBottomRight }) => (
  <section className="component promotionTeaser">
    <Container fluid className="color_invert">
      <div className="row">
        <div className="col-md-8 top">
          {promotionTeaserImageLeft &&
            <Image fluid={promotionTeaserImageLeft.fluid} />
          }
        </div>
        <div className="col-md-4 top d-none d-md-block">
          {promotionTeaserImageRightTop &&
            <Image fluid={promotionTeaserImageRightTop.fluid} />
          }
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 leftMain d-none d-md-block">
          {promotionTeaserImageBottomLeft &&
            <Image fluid={promotionTeaserImageBottomLeft.fluid} />
          }
        </div>
        <div className="col-md-8 contentColumn mt-3 mt-md-0 pt-md-4 pl-md-4">
          <h2 className="h6 mb-3 handwritten">{promotionTeaserTitle}</h2>
          <div className="text-left">{richTextRenderer(promotionTeaserContent)}</div>
        </div>
      </div>
      <div className="row mt-4 bottom">
        <div className="col-4">
          {promotionTeaserImageBottomLeft &&
            <Image fluid={promotionTeaserImageBottomLeft.fluid} />
          }
        </div>
        <div className="col-4">
          {promotionTeaserImageBottomRight &&
            <Image fluid={promotionTeaserImageBottomRight.fluid} />
          }
        </div>
        <div className="col-4">
          {promotionTeaserImageRightBottom &&
            <Image fluid={promotionTeaserImageRightBottom.fluid} />
          }
        </div>
      </div>
    </Container>
  </section>
)

export default PromotionTeaser3
