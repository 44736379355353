import React from "react"
import { Container } from "react-bootstrap"
import richTextRenderer from "../helper/richTextRenderer"

const GeneralContentFullWidth = ({ additionalClass, handwritten, textCentered, twoColumns, title, content, id }) => (
  <section className={`component generalContent ${additionalClass} ${twoColumns ? 'columns' : ''}`} id={id}>
    <Container fluid className={additionalClass}>
      <div className="row justify-content-center">
          <div className={`col-12`}>
              <h2 className={`h3 mb-5 ${handwritten ? 'handwritten' : ''} ${textCentered ? 'text-center' : 'text-center'}`}>{title}</h2>
              <div className="contentColumn">
                {richTextRenderer(content)}
              </div>
          </div>
      </div>
    </Container>
  </section>
)

export default GeneralContentFullWidth
