import React from "react"
import Icon from "../modules/icon"
import Slider from 'react-slick'
import Image from 'gatsby-image'

const NextArrow = (props) => {
  const { onClick } = props

  return (
    <button className="swiper-button-next" onClick={onClick}>
      <Icon name="chevronRight" width="50" height="50" />
    </button>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props

  return (
    <button className="swiper-button-prev" onClick={onClick}>
      <Icon name="chevronLeft" width="50" height="50" />
    </button>
  )
}

const ImageSlider = ({ additionalClass, images }) => {
  
  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  }

  return (
    <section className={`component imagesSlider ${additionalClass}`}>
      <div className="slider-container">
        <Slider className="image-slider" {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              {image.fields && <img src={image.fields.file.url} alt="preview" />}
              {(image.fluid) && <Image fluid={image.fluid} alt="Lieblingsplatz Hotels" />}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default ImageSlider
