import React, { Component } from "react"

class RestraurantBooking extends Component {

  render() {
    const {theme} = this.props

    return (
      <section className="component restraurantBooking">
        <iframe loading="lazy" title="restraurantBooking" frameBorder="0" src={`https://preview.lieblingsplatz-hotels.de/bookATable_${theme}.html`} width="100%" height="50"></iframe>
      </section>
    )
  }
}

export default RestraurantBooking
