import React from "react"
import { Container } from "react-bootstrap"
import Icon from "../modules/icon"

const FourTeaser = ({ additionalClass, style, icon1, icon2, icon3, icon4, title1, title2, title3, title4, content1, content2, content3, content4, title }) => (
  <section className={`component fourTeaser ${additionalClass}`} style={style}>
    <Container fluid className={`${additionalClass}`}>
      <div className="row">
        <div className="col-12 text-center mb-5">
          <h2 className="handwritten">{title}</h2>
        </div>
        <div className="col-md-3 text-center">
          <Icon name={icon1} width="50" height="50" />
          <h2 className="h5 mb-3 mt-4 text-uppercase">{title1}</h2>
          <p>{content1}</p>
        </div>
        <div className="col-md-3 text-center">
          <Icon name={icon2} width="50" height="50" />
          <h2 className="h5 mb-3 mt-4 text-uppercase">{title2}</h2>
          <p>{content2}</p>
        </div>
        <div className="col-md-3 text-center">
          <Icon name={icon3} width="50" height="50" />
          <h2 className="h5 mb-3 mt-4 text-uppercase">{title3}</h2>
          <p>{content3}</p>
        </div>
        <div className="col-md-3 text-center">
          <Icon name={icon4} width="50" height="50" />
          <h2 className="h5 mb-3 mt-4 text-uppercase">{title4}</h2>
          <p>{content4}</p>
        </div>
      </div>
    </Container>
  </section>
)

export default FourTeaser
