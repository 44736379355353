import React from "react"
import { Container } from "react-bootstrap"
import Image from 'gatsby-image'
import richTextRenderer from "../helper/richTextRenderer"

const PromotionTeaser2 = ({ promotionTeaserTitle, promotionTeaserContent, promotionTeaserTitleImage, promotionTeaserImageLeft, promotionTeaserImageRightTop, promotionTeaserImageRightBottom, promotionTeaserImageBottomLeft, promotionTeaserImageBottomRight }) => (
  <section className="component promotionTeaser bgDark">
    <Container fluid>
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-3 left">
              {(promotionTeaserImageLeft && promotionTeaserImageLeft.fields) && <img src={promotionTeaserImageLeft.fields.file.url} alt="preview" />}
              {(promotionTeaserImageLeft && promotionTeaserImageLeft.fluid) && <Image fluid={promotionTeaserImageLeft.fluid} alt="Lieblingsplatz Hotels" />}
            </div>
            <div className="col-md-9 contentColumn text-center mt-3 mt-md-0">
              {(promotionTeaserTitleImage && promotionTeaserTitleImage.fields) && <img className="img-m mb-5 mx-auto" src={promotionTeaserTitleImage.fields.file.url} alt="preview" />}
              {(promotionTeaserTitleImage && promotionTeaserTitleImage.fluid) && <Image className="img-m mb-5 mx-auto" fluid={promotionTeaserTitleImage.fluid} alt="Lieblingsplatz Hotels" />}
              <h2 className="h6 mb-3 text-uppercase text-center">{promotionTeaserTitle}</h2>
              <div className="text-left">{richTextRenderer(promotionTeaserContent)}</div>
            </div>
          </div>
        </div>
        
        <div className="col-md-4 right">
          <div>
            {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fields) && <img src={promotionTeaserImageRightTop.fields.file.url} alt="preview" />}
            {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fluid) && <Image fluid={promotionTeaserImageRightTop.fluid} alt="Lieblingsplatz Hotels" />}
          </div>
          <div className="mt-3">
            {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fields) && <img src={promotionTeaserImageRightBottom.fields.file.url} alt="preview" />}
            {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fluid) && <Image fluid={promotionTeaserImageRightBottom.fluid} alt="Lieblingsplatz Hotels" />}
          </div>
        </div>
      </div>
      <div className="row mt-3 bottom">
        <div className="col-6">
          {(promotionTeaserImageBottomLeft && promotionTeaserImageBottomLeft.fields) && <img src={promotionTeaserImageBottomLeft.fields.file.url} alt="preview" />}
          {(promotionTeaserImageBottomLeft && promotionTeaserImageBottomLeft.fluid) && <Image fluid={promotionTeaserImageBottomLeft.fluid} alt="Lieblingsplatz Hotels" />}
        </div>
        <div className="col-6">
          {(promotionTeaserImageBottomRight && promotionTeaserImageBottomRight.fields) && <img src={promotionTeaserImageBottomRight.fields.file.url} alt="preview" />}
          {(promotionTeaserImageBottomRight && promotionTeaserImageBottomRight.fluid) && <Image fluid={promotionTeaserImageBottomRight.fluid} alt="Lieblingsplatz Hotels" />}
        </div>
      </div>
    </Container>
  </section>
)

export default PromotionTeaser2
