import React from "react"
import { Container } from "react-bootstrap"
import Carousel from 'react-bootstrap/Carousel'
import Image from 'gatsby-image'
import richTextRenderer from "../helper/richTextRenderer"

const ContentTeaser = ({ additionalClass, title, content, carousel, id }) => (
  <section className={`component contentTeaser ${additionalClass}`} id={id}>
    <Container fluid className={`${!additionalClass ? 'color_invert' : ''}`}>
      <div className="row">
        <div className="col-md-8">
          {(carousel && carousel.length) ? (
            carousel && carousel.length > 1 ? (
              <Carousel>
                {
                  carousel.map((item, index) => (
                    <Carousel.Item key={index}>
                      {item.fields && <img src={item.fields.file.url} className="d-block w-100" alt="preview" />}
                      {(item.fluid) && <Image fluid={item.fluid} alt="Lieblingsplatz Hotels" className="d-block w-100" />}
                    </Carousel.Item>
                  ))
                }
              </Carousel>
            ) : (
              <div>
                {carousel[0].fields && <img src={carousel[0].fields.file.url} className="d-block w-100" alt="preview" />}
                {(carousel[0].fluid) && <Image fluid={carousel[0].fluid} alt="Lieblingsplatz Hotels" className="d-block w-100" />}
              </div>
            )
          ) : ( null )
          }
        </div>
        <div className="col-md-4 contentColumn">
          <h2 className="h2 mb-3 handwritten">{title}</h2>
          <div>{richTextRenderer(content)}</div>
        </div>
      </div>
    </Container>
  </section>
)

export default ContentTeaser
