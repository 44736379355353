import React from "react"
import { Container } from "react-bootstrap"
import Image from 'gatsby-image'
import richTextRenderer from "../helper/richTextRenderer"
import BackgroundImage from "gatsby-background-image"

const PromotionTeaser = ({ additionalClass, promotionTeaserTitle, promotionTeaserContent, promotionTeaserBgImage, promotionTeaserImageLeft, promotionTeaserImageRight1, promotionTeaserImageRightTop, promotionTeaserImageRightBottom, promotionTeaserTitleImage, id }) => {
  
  const background = [
    promotionTeaserBgImage.fluid,
    `linear-gradient(180deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.84) 100%)`
  ]

  if (promotionTeaserBgImage) {
    return (
      <BackgroundImage
        Tag="section"
        className={`component promotionTeaser ${additionalClass} ${promotionTeaserBgImage ? 'overlay' : ''}`}
        id={id}
        fluid={background.reverse()}
      >
        <Container fluid className={`${!promotionTeaserBgImage ? 'color_invert' : ''}`}>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-3 left">
                  {(promotionTeaserImageLeft && promotionTeaserImageLeft.fields) && <img src={promotionTeaserImageLeft.fields.file.url} alt="preview" />}
                  {(promotionTeaserImageLeft && promotionTeaserImageLeft.fluid) && <Image fluid={promotionTeaserImageLeft.fluid} alt="Lieblingsplatz Hotels" />}
                </div>
                <div className="col-md-6 contentColumn text-center mt-3 mt-md-0">
                  {(promotionTeaserTitleImage && promotionTeaserTitleImage.fields) && <img className="img-m mb-5 mx-auto" src={promotionTeaserTitleImage.fields.file.url} alt="preview" />}
                  {(promotionTeaserTitleImage && promotionTeaserTitleImage.fluid) && <Image className="img-m mb-5 mx-auto" fluid={promotionTeaserTitleImage.fluid} alt="Lieblingsplatz Hotels" />}
                  <h2 className="h6 mb-3 text-uppercase text-center">{promotionTeaserTitle}</h2>
                  <div className="text-left">{richTextRenderer(promotionTeaserContent)}</div>
                </div>
                <div className="col-md-3 left d-none d-md-block">
                  {(promotionTeaserImageRight1 && promotionTeaserImageRight1.fields) && <img src={promotionTeaserImageRight1.fields.file.url} alt="preview" />}
                  {(promotionTeaserImageRight1 && promotionTeaserImageRight1.fluid) && <Image fluid={promotionTeaserImageRight1.fluid} alt="Lieblingsplatz Hotels" />}
                </div>
              </div>
            </div>
            
            <div className="col-md-4 right">
              <div>
                {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fields) && <img src={promotionTeaserImageRightTop.fields.file.url} alt="preview" />}
                {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fluid) && <Image fluid={promotionTeaserImageRightTop.fluid} alt="Lieblingsplatz Hotels" />}
              </div>
              <div className="mt-3">
                {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fields) && <img src={promotionTeaserImageRightBottom.fields.file.url} alt="preview" />}
                {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fluid) && <Image fluid={promotionTeaserImageRightBottom.fluid} alt="Lieblingsplatz Hotels" />}
              </div>
            </div>
          </div>
        </Container>
      </BackgroundImage>
    )
  }

  return (
    <section
      className={`component promotionTeaser ${additionalClass} ${promotionTeaserBgImage ? 'overlay' : ''}`}
      id={id}
    >
      <Container fluid className={`${!promotionTeaserBgImage ? 'color_invert' : ''}`}>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3 left">
                {(promotionTeaserImageLeft && promotionTeaserImageLeft.fields) && <img src={promotionTeaserImageLeft.fields.file.url} alt="preview" />}
                {(promotionTeaserImageLeft && promotionTeaserImageLeft.fluid) && <Image fluid={promotionTeaserImageLeft.fluid} alt="Lieblingsplatz Hotels" />}
              </div>
              <div className="col-md-6 contentColumn text-center mt-3 mt-md-0">
                {(promotionTeaserTitleImage && promotionTeaserTitleImage.fields) && <img className="img-m mb-5 mx-auto" src={promotionTeaserTitleImage.fields.file.url} alt="preview" />}
                {(promotionTeaserTitleImage && promotionTeaserTitleImage.fluid) && <Image className="img-m mb-5 mx-auto" fluid={promotionTeaserTitleImage.fluid} alt="Lieblingsplatz Hotels" />}
                <h2 className="h6 mb-3 text-uppercase text-center">{promotionTeaserTitle}</h2>
                <div className="text-left">{richTextRenderer(promotionTeaserContent)}</div>
              </div>
              <div className="col-md-3 left d-none d-md-block">
                {(promotionTeaserImageRight1 && promotionTeaserImageRight1.fields) && <img src={promotionTeaserImageRight1.fields.file.url} alt="preview" />}
                {(promotionTeaserImageRight1 && promotionTeaserImageRight1.fluid) && <Image fluid={promotionTeaserImageRight1.fluid} alt="Lieblingsplatz Hotels" />}
              </div>
            </div>
          </div>
          
          <div className="col-md-4 right">
            <div>
              {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fields) && <img src={promotionTeaserImageRightTop.fields.file.url} alt="preview" />}
              {(promotionTeaserImageRightTop && promotionTeaserImageRightTop.fluid) && <Image fluid={promotionTeaserImageRightTop.fluid} alt="Lieblingsplatz Hotels" />}
            </div>
            <div className="mt-3">
              {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fields) && <img src={promotionTeaserImageRightBottom.fields.file.url} alt="preview" />}
              {(promotionTeaserImageRightBottom && promotionTeaserImageRightBottom.fluid) && <Image fluid={promotionTeaserImageRightBottom.fluid} alt="Lieblingsplatz Hotels" />}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default PromotionTeaser
